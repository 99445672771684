<template>
  <v-card>
    <!-- It is necessary to define a height in order to the "scrollable" option to work:
					https://vuetifyjs.com/en/components/dialogs/#scrollable
					https://vuetifyjs.com/en/api/v-dialog/#props
					-->
    <v-card-text>
      <v-layout row style="margin-top: 8px; height: 100%">
        <v-flex xs1>
          <v-btn :disabled="!isPreviousButtonAvailable" @click="showPreviousLevel" color="#028940" height="100%">
            <v-icon color="#ffffff">fal fa-angle-left</v-icon>
          </v-btn>
        </v-flex>
        <!-- The Menu's Levels and Options to choose -->
        <v-flex xs8>
          <v-tabs v-model="selectedTab" show-arrows>
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
            <v-tab v-for="i in itemMenuLevels.length" :key="i" :href="'#tab-' + i">
              {{ itemMenuLevels[i - 1].description }}
              <span v-if="itemMenuLevels[i - 1].mandatory">*</span>
            </v-tab>
            <v-tab-item v-for="j in itemMenuLevels.length" :key="j" :value="'tab-' + j">
              <v-card>
                <!-- An alert message,
                notifying the User that the selected Items for this level, are the maximum number allowed. -->
                <v-card-subtitle
                  v-if="
                    menuLevelsItemsIdentifiers[j - 1] &&
                    itemMenuLevels[j - 1].maximum_items === menuLevelsItemsIdentifiers[j - 1].length
                  "
                >
                  <v-banner
                    v-if="itemMenuLevels[j - 1].maximum_items && itemMenuLevels[j - 1].multiple"
                    single-line
                    outlined
                    rounded
                    elevation="4"
                    style="text-align: center"
                  >
                    <v-icon color="info"> fal fa-exclamation</v-icon>
                    {{ $vuetify.lang.t("$vuetify.itemsMenuLevelsCardPage.maxChoices") }} :
                    {{ itemMenuLevels[j - 1].maximum_items }}
                  </v-banner>
                </v-card-subtitle>
                <v-card-text>
                  <ItemsListCard
                    :items="itemMenuLevels[j - 1].items"
                    :selectedItemsIdentifiers="menuLevelsItemsIdentifiers[j - 1]"
                    :displayVariantsDialogAuto="false"
                    :isStockInfoAvailable="false"
                    :context="'menu'"
                    @select-item="selectItem"
                  ></ItemsListCard>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-flex>
        <v-flex xs1>
          <v-btn :disabled="!isNextButtonAvailable" @click="showNextLevel" color="#028940" height="100%">
            <v-icon color="#ffffff">fal fa-angle-right</v-icon>
          </v-btn>
        </v-flex>
        <!-- The Menu's Levels and Chosen Options -->
        <v-flex xs2>
          <h3>{{ $vuetify.lang.t("$vuetify.itemsMenuLevelsCardPage.summary") }}</h3>
          <v-list two-line class="cart-items-list">
            <v-list-item
              v-for="(menuLevels, i) in menuLevelsOptionsItems"
              :key="i"
              class="v-list-item--link"
              @click="goToLevel(i)"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="cart-item-name"
                  v-text="menuLevelsOptionsItems[i].description"
                ></v-list-item-title>
                <v-list-item-subtitle v-for="(menuLevelsItems, l) in menuLevels.items" :key="l">
                  {{ menuLevelsItems.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <div>
        <v-btn large text @click="closeDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }} </v-btn>

        <v-btn :disabled="!isAddToCartButtonAvailable" @click="addToCart" large class="primary">
          {{ $vuetify.lang.t("$vuetify.add") }}</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ItemsMenuLevelsCard",
  //Due to the following error, it was not possible to declare the Component as we use to: components: { ItemsListCard }
  //Unknown custom element: <ItemsListCard> - did you register the component correctly? For recursive components, make sure to provide the "name" option.
  // https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
  components: { ItemsListCard: () => import("@/components/ItemsListCard.vue") },
  props: {
    //An object containing a complete definition of the Item, including its Menu Levels (item.data.menuLevels)
    item: Object,
    editItemInCart: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    itemClone: null,
    itemInCartOrder: null,
    //This component is used in two different contexts:
    //1 - from the catalog with the aim of adding the choices to the Cart
    //2 - from the Cart with the aim of editing the choices already on the Cart
    //These two different inputs have different ways of storing the information on the Item.
    //Therefore, it is necessary to normalize the information related to the Menu Levels.
    itemMenuLevels: {},
    //A list with the identifiers of the Level's which have chosen Items.
    //It is used to verify if the choices for the Mandatory Levels were already made.
    selectedLevelsIdentifiers: [],
    //A dictionary where each position represents a Menu Level. In each Menu Level there is a list of dictionaries.
    // Each element of the list represents the chosen Item.
    menuLevelsOptionsItems: {},
    //A dictionary where each position represents a Menu Level. In each Menu Level there is a list of Items' identifiers,
    // which represent the chosen Items.
    menuLevelsItemsIdentifiers: [],
    //To store the value of the visible Tab. Example: tab-1
    selectedTab: null,
    //TODO: Unable to use this in the computed...: fix me
    //A flag which states if the Add To Cart button is available.
    isAddToCartButtonAvailable: false,
  }),
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    /**
     * This function is used on the "Seguinte" button. It changes the selected Tab to the following Tab.
     */
    showNextLevel: function () {
      //1 - Identifying the Menu Level Number, by checking the clicked Tab
      let levelNumber = this.selectedTab.replace("tab-", "") - 1;
      //2 - The following Tab is the next level, having the limit of the total number of Levels that exist on the Menu
      let nextTabIdentifier = Math.min(parseInt(levelNumber) + parseInt(2), this.itemMenuLevels.length);

      this.selectedTab = "tab-" + nextTabIdentifier;
    },
    /**
     * This function is used on the "Anterior" button. It changes the selected Tab to the previous Tab.
     */
    showPreviousLevel: function () {
      //1 - Identifying the Menu Level Number, by checking the clicked Tab
      let levelNumber = this.selectedTab.replace("tab-", "") - 1;
      //2 - The previous Tab is the previous level, having 0 as the lower limit
      let nextTabIdentifier = Math.max(parseInt(levelNumber), 0);

      this.selectedTab = "tab-" + nextTabIdentifier;
    },
    /**
     * Based on the given Level Number,
     * this function determines which is the corresponding Tab and sets it as the selected Tab.
     * This is used on the "helper" panel by the click on the Level.
     *
     * @param levelNumber
     */
    goToLevel: function (levelNumber) {
      let nextTabIdentifier = parseInt(levelNumber) + parseInt(1);
      this.selectedTab = "tab-" + nextTabIdentifier;
    },
    /**
     * Evaluating if the button to add Items to the Cart can be enabled.
     * The rules evaluated are:
     * 1 - all the mandatory levels must have chosen Items
     * @returns {boolean}
     */
    checkCartButtonIsAvailable() {
      // this.$log.debug("Detail Menu | Evaluate the Add button can be Enabled");
      if (this.itemMenuLevels) {
        // this.$log.debug("Detail Menu | Iterating over all the levels of the menu");
        for (let level of this.itemMenuLevels) {
          // this.$log.debug("Detail Menu | Analysing level " + level.description + " (" + level.id + ") ");
          if (level.mandatory === 1) {
            // this.$log.debug("Detail Menu | The level " + level.description + " (" + level.id + ")  is mandatory.");
            if (this.selectedLevelsIdentifiers.indexOf(level.id) === -1) {
              // this.$log.debug(
              //   "Detail Menu | The mandatory level " + level.description + " (" + level.id + ") was not selected"
              // );
              return false;
            }
          }
        }
      }
      return true;
    },
    /**
     * The function which stores the Menu (Item) choices. This is the main purpose of the function,
     * but there are more actions performed:
     * 1 - automatically changing the selected Tab to the following Level of the Menu
     * 2 - updating the list of the identifiers of the Level's which have chosen Items
     * 3 - updating the list of Items chosen on each level
     *
     * @param item
     */
    selectItem: function (item) {
      //1 - Identifying the Menu Level Number, by checking the clicked Tab
      let levelNumber = this.selectedTab.replace("tab-", "") - 1;
      let menuLevel = this.itemMenuLevels[levelNumber];

      //2 - Determining which should be the following selected Tab, according to the current Menu Level
      let nextTabIdentifier = null;
      if (menuLevel.multiple) {
        nextTabIdentifier = parseInt(levelNumber) + parseInt(1);
      } else {
        nextTabIdentifier = Math.min(parseInt(levelNumber) + parseInt(2), this.itemMenuLevels.length);
      }

      //3 - Adding the selected Item to the List of Items chosen in a Menu Level.
      //4 - Adding the selected Item Identifier to the List of Items chosen in a Menu Level.
      //This will be used to pre-select the Items on the ItemsListCard
      const index = this.menuLevelsItemsIdentifiers[levelNumber].indexOf(item.id);
      if (index > -1) {
        this.menuLevelsItemsIdentifiers[levelNumber] = this.menuLevelsItemsIdentifiers[levelNumber].filter(
          (value) => value !== item.id
        );
        this.menuLevelsOptionsItems[levelNumber].items = this.menuLevelsOptionsItems[levelNumber].items.filter(
          ({ code }) => code !== item.code
        );
      } else {
        if (!this.itemMenuLevels[levelNumber].multiple) {
          this.menuLevelsOptionsItems[levelNumber].items = [item];
          this.menuLevelsItemsIdentifiers[levelNumber] = [item.id];
        } else {
          if (this.itemMenuLevels[levelNumber].maximum_items === this.menuLevelsItemsIdentifiers[levelNumber].length) {
            this.$store.dispatch(
              "alert/error",
              this.$vuetify.lang.t("$vuetify.itemsMenuLevelsCardPage.allowed") +
                " " +
                this.itemMenuLevels[levelNumber].maximum_items +
                " " +
                this.$vuetify.lang.t("$vuetify.itemsMenuLevelsCardPage.itemsInLevel") +
                " " +
                this.itemMenuLevels[levelNumber].description
            );
          } else {
            this.menuLevelsOptionsItems[levelNumber].items.push(item);
            //https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
            this.menuLevelsItemsIdentifiers[levelNumber] = [...this.menuLevelsItemsIdentifiers[levelNumber], item.id];
          }
        }
      }
      //5 - Automatically changing the selected Tab to the following Level of the Menu
      // TODO: Force redraw changing tab: fix me
      this.selectedTab = "none";
      this.selectedTab = "tab-" + nextTabIdentifier;

      //6 - Adding the Level's Identifier to the list which tracks the Levels with chosen Items
      this.selectedLevelsIdentifiers = this.getSelectedLevelsIdentifiers();

      //7 - Evaluating if the button to add Items to the Cart can be enabled
      this.isAddToCartButtonAvailable = this.checkCartButtonIsAvailable();
    },
    addToCart: function () {
      this.$log.debug("Detail Menu | Adding the Menu Item to the Cart");

      let itemId = this.itemClone.id;
      let parentItemId = this.itemClone.data.parentItem;
      let menuChoices = this.menuLevelsOptionsItems;
      if (this.editItemInCart) {
        let itemMenu = menuChoices;
        let itemOrder = this.itemInCartOrder;
        this.$store.dispatch("cart/editMenuInCart", { itemId, itemMenu, itemOrder });
      } else {
        this.$store.dispatch("cart/addToCart", { itemId, parentItemId, menuChoices });
      }

      this.$emit("close-dialog");
    },
    getSelectedLevelsIdentifiers: function () {
      // this.$log.debug("Detail Menu | Identifying the Levels Identifiers which already have chosen Items");
      let levelsIdentifiers = [];

      for (let i = 0; i < Object.keys(this.menuLevelsOptionsItems).length; i++) {
        // this.$log.debug("Detail Menu | Menu Level " + i);
        // this.$log.debug("Detail Menu | Menu Level Options Length " + this.menuLevelsOptionsItems[i].length);
        if (this.menuLevelsOptionsItems[i].items.length > 0) {
          levelsIdentifiers.push(this.itemMenuLevels[i].id);
        }
      }

      return levelsIdentifiers;
    },
  },
  computed: {
    isNextButtonAvailable: function () {
      //1 - Identifying the Menu Level Number, by checking the clicked Tab
      if (this.selectedTab) {
        let levelNumber = this.selectedTab.replace("tab-", "") - 1;
        return levelNumber + 1 < this.itemMenuLevels.length;
      }
      return false;
    },
    isPreviousButtonAvailable: function () {
      //1 - Identifying the Menu Level Number, by checking the clicked Tab
      if (this.selectedTab) {
        let levelNumber = this.selectedTab.replace("tab-", "") - 1;
        return levelNumber - 1 >= 0;
      }
      return false;
    },
  },
  async mounted() {
    this.itemMenuLevels = [];
    this.itemClone = Object.assign(JSON.parse(JSON.stringify(this.item)));
    this.itemInCartOrder = this.itemClone.order;

    if (this.itemClone.data) {
      this.itemMenuLevels = this.itemClone.data.menuLevels;

      //Getting all the Active Items
      let result = await this.$store.dispatch("items/getAll");

      for (let i = 0; i < this.itemMenuLevels.length; i++) {
        for (let j = 0; j < this.itemMenuLevels[i].items.length; j++) {
          //@modified ana.castro 2022-12-09
          //Verifying if the Menu's Item is an active Item
          // let result = await this.$store.dispatch("items/getItemActive", this.itemMenuLevels[i].items[j].id);
          // if (result.length === 0) {
          //   this.itemMenuLevels[i].items.splice(j, 1);
          //   j--;
          // }

          if (result.find((item) => item["code"] === this.itemMenuLevels[i].items[j]["code"]) === undefined) {
            this.itemMenuLevels[i].items.splice(j, 1);
          }
        }
      }

      for (let i = 0; i < this.itemMenuLevels.length; i++) {
        this.menuLevelsOptionsItems[i] = {
          id: this.itemMenuLevels[i].id,
          mandatory: this.itemMenuLevels[i].mandatory,
          description: this.itemMenuLevels[i].description,
          multiple: this.itemMenuLevels[i].multiple,
          maximum_items: this.itemMenuLevels[i].maximum_items,
          items: [],
        };

        this.menuLevelsItemsIdentifiers[i] = [];
      }
    } else {
      this.menuLevelsOptionsItems = [];

      this.itemMenuLevels = this.item.menu_items;

      for (let i = 0; i < Object.keys(this.itemMenuLevels).length; i++) {
        this.menuLevelsOptionsItems[i] = {
          id: this.itemMenuLevels[i].id,
          mandatory: this.itemMenuLevels[i].mandatory,
          description: this.itemMenuLevels[i].description,
          multiple: this.itemMenuLevels[i].multiple,
          maximum_items: this.itemMenuLevels[i].maximum_items,
          items: this.itemMenuLevels[i].items,
        };
        this.menuLevelsItemsIdentifiers[i] = [];
        for (let j = 0; j < this.itemMenuLevels[i].items.length; j++) {
          this.menuLevelsItemsIdentifiers[i].push(this.itemMenuLevels[i].items[j].id);
        }
      }

      this.$store.dispatch("items/get", this.item.item_id).then((result) => {
        this.itemClone = result[0];
        this.itemMenuLevels = result[0].data.menuLevels;

        //Adding the Level's Identifier to the list which tracks the Levels with chosen Items
        this.selectedLevelsIdentifiers = this.getSelectedLevelsIdentifiers();
        this.isAddToCartButtonAvailable = true;
      });
    }
  },
};
</script>

<style scoped></style>
